var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[(_vm.visible)?_c('div',{staticClass:"flex items-center justify-center fixed left-2 right-2 z-50",class:{
         'opacity-0': !_vm.visible,
         'opacity-100': _vm.visible,
         'bottom-6': _vm.position === 'bottom',
         'top-16': _vm.position === 'top',
       }},[_c('div',{staticClass:"flex items-center py-3 rounded shadow-lg text-white",class:{
           'bg-green-700': _vm.variant === 'success',
           'bg-red-700': _vm.variant === 'error',
           'bg-blue-700': _vm.variant === 'info',
           'bg-orange-700': _vm.variant === 'warning',
           'bg-grey-900 px-4': _vm.variant === 'basic',
         }},[(_vm.variant != 'basic')?_c('alert-icon',{staticClass:"pl-4 pr-3",attrs:{"variant":_vm.variant}}):_vm._e(),_c('div',{staticClass:"flex-grow text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(_vm.dismissible && _vm.variant != 'basic')?_c('div',{staticClass:"ml-3 mr-3 cursor-pointer",on:{"click":function($event){_vm.visible = false}}},[_c('x-icon')],1):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }